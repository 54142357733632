import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { navItems } from './sidebar-data';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { AppHorizontalNavItemComponent } from './nav-item/nav-item.component';
import { CommonModule } from '@angular/common';
import { NavService } from '../../../../../../../shared/src/lib/services/nav.service';
import {NavItem} from "../../vertical/sidebar/nav-item/nav-item";
import {ApiService} from "../../../../../../../shared/src/lib/services/api.service";
import { environment } from '../../../../../../../shared/src/lib/environments/environment';

@Component({
  selector: 'app-horizontal-sidebar',
  standalone: true,
  imports: [AppHorizontalNavItemComponent, CommonModule],
  templateUrl: './sidebar.component.html',
})
export class AppHorizontalSidebarComponent implements OnInit {
  navItems = navItems;
  parentActive = '';

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    public navService: NavService,
    private apiService: ApiService,
    public router: Router,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1100px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe(
      () => (this.parentActive = this.router.url.split('/')[1]),
    );
  }

  ngOnInit(): void {
    this.validItems();
  }

  validItems() {
    let udata = this.apiService.getUserData();
    let role = udata.ROLE;
    let permissions = udata.PERMISSIONS.split(',');
    let items: NavItem[] = [];
    const shouldDisplayLivePnLFirst = environment.firstIndexPnl;
    const hasLivePnL = (name: string) => items.some(item => item.displayName === name);
    let item = navItems.filter(item => item.displayName == 'Users' ||  item.displayName == 'Dashboard' || item.displayName == 'Dynamic Data')
    items = [...items, ...item];
    if (permissions.includes('canSetCommission')){
      let item = navItems.filter(item => item.displayName == 'Commission')
      items = [...items, ...item]
    }if (permissions.includes('canSetTakeRisk')){
      let item = navItems.filter(item => item.displayName == 'Risk Taking')
      items = [...items, ...item];
    }if(permissions.includes('canConfigureCountry')){
      let item = navItems.filter(item => item.displayName == 'Supported Countries')
      items = [...items, ...item];
    }if (permissions.includes('canConfigureCurrency')){
      let item = navItems.filter(item => item.displayName == 'Exchange Rate')
      items = [...items, ...item];
    }if (permissions.includes('canConfigureSport')){
      let item = navItems.filter(item => item.displayName == 'Supported Sports' )
      items = [...items, ...item];
    }if (permissions.includes('canSetupBetFairAccounts')){
      let item = navItems.filter(item => item.displayName == 'Betfair Accounts')
      items = [...items, ...item];
    }if (permissions.includes('canViewReports')){
      let item = navItems.filter(item => item.displayName == 'Reports' || item.displayName == 'Activity Report' || item.displayName == 'Account Statement')
      items = [...items, ...item];
    }if (permissions.includes('canEnableWhiteLabel')){
      let item = navItems.filter(item => item.displayName == 'White Labels')
      items = [...items, ...item];
    }if (permissions.includes('canEnableLiveStream')){
      let item = navItems.filter(item => item.displayName == 'Live Stream')
      items = [...items, ...item];
    }if (permissions.includes('canEnableLiveScore')){
      let item = navItems.filter(item => item.displayName == 'Live Score')
      items = [...items, ...item];
    }if (permissions.includes('canSendNotifications')){
      let item = navItems.filter(item => item.displayName == 'Notificaions')
      items = [...items, ...item];
    } if (shouldDisplayLivePnLFirst) {
      let livePnLItem = navItems.filter(item => item.displayName === 'Live PnL');
      if (livePnLItem.length > 0) {
        items = [...livePnLItem, ...items];
      }
    }
    if (!shouldDisplayLivePnLFirst && permissions.includes('canViewPL') && !hasLivePnL('Live PnL')) {
      let item = navItems.filter(item => item.displayName === 'Live PnL');
      items = [...items, ...item];
    }if (permissions.includes('canHandleFunds')){
      let item = navItems.filter(item => item.navCap == 'Funds' ||  item.displayName == 'Deposit' || item.displayName == 'Withdraw' || item.displayName == 'Transfer' || item.displayName == 'Wallets')
      items = [...items, ...item];
    }if (role == 'super_admin'){
      let item = navItems.filter(item => item.displayName == 'Roles' || item.displayName == 'Bet Status' || item.displayName == 'Bet Info' || item.displayName == 'Resettlement' || item.displayName == 'Betting P/L'  || item.displayName == 'Feature Flags')
      items = [...items, ...item];
    }

    item = navItems.filter(item => item.displayName == 'Change Password');
    items = [...items, ...item];

    //set sidnav lists based on permissions

    this.navItems = items;
  }

}
